import React from 'react';
import '../App.css';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Logo from '../images/family-gains-logo.png';
import Cart from './Cart';

const Nav = () => {
  // Redux variables
  const cart = useSelector((state) => state.shop.cart);

  return (
    <nav>
      <div className="flex justify-between items-center p-4 shadow-under">
        <Link to="/">
          <img
            src={Logo}
            alt="Family Gains Logo"
            className="h-[30px] pl-4 hover:brightness-75"
          ></img>
        </Link>
        {/* TODO: Hide links below if not on a public route or in checkout */}
        <div className="flex justify-evenly pr-4">
          <Link className="flex items-center nav-links" to="/store">
            <span className="material-symbols-outlined" id="nav-icons">
              storefront
            </span>
            <p className="hidden sm:flex pr-4">Store</p>
          </Link>
          <Link className="flex items-center nav-links" to="/contact">
            <span className="material-symbols-outlined pl-8" id="nav-icons">
              mail
            </span>
            <p className="hidden sm:flex">Contact</p>
          </Link>
          <Cart />
        </div>
      </div>
    </nav>
  );
};

export default Nav;
