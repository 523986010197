import { React, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addToCart } from '../store/slices/shop';
import BackToStoreBtn from './BackToStoreBtn';
import '../App.css';

const Product = (props) => {
	// variables
	const dispatch = useDispatch();
	// We get the product id from the url
	const [productId, setProductId] = useState(window.location.pathname.slice(7));

	return (
		<div className='m-8'>
			<BackToStoreBtn />
			<div className='mt-8'>
				{props.productList.map((product) => {
					if (product._id === productId) {
						return (
							<div
								key={productId}
								className='flex flex-col justify-center items-center'
							>
								<h1 className='text-center text-3xl md:text-4xl pb-8 text-familygainsred'>
									{product.name}
								</h1>
								<img
									src={product.image}
									alt={product.name}
									className='sm:w-[400px] sm:h-[400px] pb-8'
								></img>
								<p className='text-center text-3xl md:text-4xl pb-4'>
									${product.price}
								</p>
								<p className='text-center text-lg md:text-xl md:w-3/5 pb-8'>
									{product.description}
								</p>
								<div className='ml-4'>
									{product.inStock === 'yes' ? (
										<button
											onClick={() => dispatch(addToCart(product))}
											className='mb-4 sm:mb-0 p-4 rounded-full text-white bg-familygainsdarkred hover:bg-familygainsdarkestred'
										>
											Add to Cart
										</button>
									) : (
										<p>Out of Stock</p>
									)}
								</div>
							</div>
						);
					}
				})}
			</div>
		</div>
	);
};

export default Product;
