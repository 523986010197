import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  addToCart,
  subtractFromCart,
  removeFromCart,
} from '../store/slices/shop';
import axios from 'axios'
import '../App.css';
import './Cart.css';

// Base URLs needed
const backendBaseURL = process.env.REACT_APP_BACKEND_URL;


const Cart = () => {
  // Variables
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.shop.cart);
  const totalItems = useSelector((state) => state.shop.totalItems);
  const subtotal = useSelector((state) => state.shop.subtotal);
  const [stringifiedCart, setStringifiedCart] = useState(JSON.stringify(cart));

  // Functions
  const closeCart = () => {
    document.getElementById('my-drawer').checked = false;
  };

  // Effects 
  // Each time cart changes, update our stringifiedCart
  useEffect(() => {
    setStringifiedCart(JSON.stringify(cart));
  }, [cart])

  return (
    <div className="drawer drawer-end">
      <div className="nav-links">
        <input id="my-drawer" type="checkbox" className="drawer-toggle" />
        <div className="sm:ml-4 flex items-center drawer-content">
          {/* Page content here */}
          <label htmlFor="my-drawer" className="drawer-button">
            <span
              className="material-symbols-outlined cart-icon sm:mt-1 pl-8"
              id="nav-icons"
            >
              shopping_bag
            </span>
            <div
              className={
                totalItems < 10 ? 'single-digit-cart' : 'double-digit-cart'
              }
            >
              {totalItems}
            </div>
            <p className="hidden sm:flex pr-4" id="cart-text">
              Cart
            </p>
          </label>
        </div>
        <div className="drawer-side">
          <label htmlFor="my-drawer" className="drawer-overlay"></label>
          <div className="menu p-4 w-80 min-h-full bg-base-200 text-base-content flex justify-between">
            {/* Sidebar content here */}
            <div>
              <div className="flex justify-between mb-4 text-lg">
                <p className="pl-28 heading text-familygainsred">My Cart</p>
                <span
                  onClick={() => closeCart()}
                  class="material-symbols-outlined close-cart-icon pt-0.5"
                >
                  cancel
                </span>
              </div>
              {/*TODO: if cart empty, show empty picture, msg to add items, and link to store, otherwise do map of items*/}
              <div className="cart-items overflow-y-scroll">
                {cart.map((product) => {
                  return (
                    <div className="flex flex-row pb-4">
                      <img src={product.image} className="item-image"></img>
                      <div className="flex flex-col justify-evenly item-details">
                        <p className="text-familygainsblack">{product.name}</p>
                        <p>{`$${product.price.toString()}`}</p>
                        <div className="flex">
                          <span
                            onClick={() => dispatch(subtractFromCart(product))}
                            className="material-symbols-outlined"
                            id="remove-icon"
                          >
                            remove
                          </span>
                          <p className="item-quantity">{`${product.quantity.toString()}`}</p>
                          <span
                            onClick={() => dispatch(addToCart(product))}
                            className="material-symbols-outlined"
                            id="add-icon"
                          >
                            add
                          </span>
                        </div>
                        <button
                          onClick={() => dispatch(removeFromCart(product))}
                          className="remove-button hover:text-familygainsdarkred flex justify-center w-[60px]"
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            {/* TODO: We show subtotal and checkout button only if cart is not empty */}
            {totalItems > 0 &&
            <div className='flex flex-col text-center text-lg'>
              <p className='mb-4 subtotal'>{`Subtotal: $${subtotal}`}</p>
              <form action={`${backendBaseURL}/checkout/create-checkout-session`} method="POST">
                  {/* We send the stringified cart to the server via a hidden input  */}
                  <input type="hidden" name="stringifiedCart" value={stringifiedCart} />
                  <button
                    type="submit"
                    className="text-center mb-4 p-4 rounded-full text-white bg-familygainsdarkred hover:bg-familygainsdarkestred"
                  >
                    Go to Checkout
                  </button>
                </form>
            </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;
