import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cart: [],
  totalItems: 0,
  subtotal: 0,
};

export const shopSlice = createSlice({
  name: 'shop',
  initialState,
  reducers: {
    // todo: add ability to have quantity of each item
    addToCart: (state, action) => {
      // make a copy of the item to add to cart so we can manipulate it
      let item = { ...action.payload };
      // check if cart array contains item with that id already
      const index = state.cart.findIndex(
        (item) => item._id === action.payload._id
      );
      // if not, then add the item with quantity of 1
      if (index <= -1) {
        item.quantity = 1;
        state.cart.push(item);
      }
      // if so, then just increment the quantity of existing item by 1
      if (index > -1) {
        state.cart.find(
          (item) => item._id === action.payload._id
        ).quantity += 1;
      }
      // increment tracker for total number of items (including quantities)
      state.totalItems += 1;
      // increment subtotal 
      state.subtotal += action.payload.price;
    },
    subtractFromCart: (state, action) => {
      // find the index of the item to remove from cart array
      const index = state.cart.findIndex(
        (item) => item._id === action.payload._id
      );
      // decrement total number of items by one
      state.totalItems -= 1;
      // decrement subtotal
      state.subtotal -= action.payload.price;
      // decrement quantity of that item in the cart, or if decrementing to 0 remove it
      state.cart[index].quantity > 1
        ? (state.cart[index].quantity -= 1)
        : state.cart.splice(index, 1);
    },
    removeFromCart: (state, action) => {
      // find the index of the item to remove from cart array
      const index = state.cart.findIndex(
        (item) => item._id === action.payload._id
      );
      // decrement total number of items by quantity of that item
      state.totalItems -= state.cart[index].quantity;
      // decrement subtotal
      state.subtotal -= (action.payload.price * state.cart[index].quantity);
      // remove that item from the cart array
      if (index > -1) {
        state.cart.splice(index, 1);
      }
    },
  },
});

export const { addToCart, subtractFromCart, removeFromCart } =
  shopSlice.actions;

export default shopSlice.reducer;
