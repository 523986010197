import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css';

const Home = () => {
  return (
    <div className="flex flex-col-reverse md:flex-row h-screen justify-center items-center">
      <div className="h-screen md:w-1/2 flex flex-col justify-start md:justify-evenly items-start p-4 sm:p-8 md:p-16">
        <h1 className="mb-4 md:mb-0 text-3xl md:text-4xl lg:text-5xl font-bold font-Montserrat text-familygainsred">
          Resources to Raise Your Family
        </h1>
        <p className="mb-4 md:mb-0 lg:text-xl">
          Raising a family is hard. We make it a little easier, with our
          educational activities and content to help your kids build academic
          skills, life skills, and positive values all while having fun!
        </p>
        <Link
          className="mb-4 sm:mb-0 p-4 rounded-full text-white bg-familygainsdarkred hover:bg-familygainsdarkestred"
          to="/store"
        >
          View Store
        </Link>
      </div>
      <img
        className="mt-6 md:block h-2/5 md:h-3/5 lg:h-5/5 2xl:ml-10 md:mr-8 rounded-full"
        id="home-image"
        src="https://i.ibb.co/d5bvggR/homepage-photo.jpg"
        alt="Happy mother and daughter doing a fist bump"
      ></img>
    </div>
  );
};

export default Home;
